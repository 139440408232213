<template>
<n-button
    size="small"
    class="mr-3 mb-3 text-white/90 rounded-md"
    :color="gl.mainColor"
    :loading="checkBalanceLoading"
    :disabled="checkBalanceLoading"
    @click="onCheckBalance">
    <!-- {{ stats.localization['trade_statistics_management_resetfixorder_button'] }} -->
    {{ stats.localization['insurance_order_open_btn'] }}
</n-button>

<base-drawer-mobile
    :label="stats.localization['insurance_order_modal_title']"
    v-model:show="show">
    <template #default>
        <n-alert class="rounded-md mb-4" type="warning" size="small">
            <div class="notes text-[10px]">
                {{ stats.localization['insurance_order_txt']  }}
            </div>
        </n-alert>

        <section class="flex items-end">
            <rb-select
                :label="stats.localization['insurance_order_price_label']"
                :class="!model.market ? 'w-6/12 mr-2' : 'w-full'"
                :options="orderExecutionTypes"
                v-model:value="orderExecution" />
            
            <rb-input
                v-if="!model.market"
                class="w-6/12 ml-2"
                v-model:value="model.rate"/>
        </section>

        <section class="flex items-center">
            <span>{{ stats.localization['trade_statistics_management_current'] }}</span>
            <span class="mx-2 rb-td-stock-icon w-16" :class="`rb-td-stock-icon-${exchange(botShortInfo?.exchange)?.code_name}`"></span>
            <span>{{ stats.localization['trade_statistics_management_tickers_for'] }}</span>
            <div class="ml-2 flex items-center">
                <rb-coin class="w-6" :coin="currency(botShortInfo?.tickers_for?.split('/').shift().trim())" />
                <div class="ml-2">{{ botShortInfo?.tickers_for }}</div>
            </div>
        </section>

        <section class="flex mt-4">
            <n-card
                size="small"
                class="mr-2 shadow border dark:border-darkCardBg bg-transparent rounded-md padding-2 w-6/12"
                content-style="padding: 4px 8px;"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section class="flex items-center">
                        <div>{{ stats.localization['trade_statistics_management_currenttickers_buy'] }}</div>
                        <div
                            class="text-green-600 font-semibold text-lg ml-2"
                            :class="!model.market ? 'underline cursor-pointer hover:no-underline' : ''"
                            @click="setPrice(botShortInfo.buy)">
                            &nbsp;{{ botShortInfo?.buy }}
                        </div>
                    </section>
                </template>
            </n-card>

            <n-card
                size="small"
                class="ml-2 shadow border dark:border-darkCardBg bg-transparent rounded-md padding-2 w-6/12"
                content-style="padding: 4px 8px;"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section class="flex items-center">
                        <div>{{ stats.localization['trade_statistics_management_currenttickers_sell'] }}</div>
                        <div
                            class="text-red-500 font-semibold text-lg ml-2"
                            :class="!model.market ? 'underline cursor-pointer hover:no-underline' : ''"
                            @click="setPrice(botShortInfo.sell)">
                            &nbsp;{{ botShortInfo?.sell }}
                        </div>
                    </section>
                </template>
            </n-card>
        </section>
        <n-divider />

        <section class="flex items-end mt-4">
            <rb-input
                label="Размер ордера"
                v-model:value="model.amount">
                <template #beforeInput v-if="botShortInfo.futures">
                    <div class="text-main font-semibold whitespace-nowrap text-base pr-2 pt-1">{{ botShortInfo?.leverage }}x</div>
                </template>
            </rb-input>
            
            <div class="flex flex-col items-center ml-2">
                <rb-coin
                    class="w-6"
                    :coin="currency(coin)" />
                <div v-if=botShortInfo.futures class="text-gray-400 dark:text-white/55 text-center whitespace-nowrap uppercase text-xs -mt-1">
                    {{ coin }}
                </div>
            </div>
        </section>

        <template v-if="symbolBalance">
            <n-divider />
            <n-statistic
                tabular-nums
                class="text-left mt-4"
                label="Доступно для усреднения">
                <n-number-animation
                    :from="0"
                    :to="+symbolBalance"
                    :precision="8"
                />
            </n-statistic>
        </template>
    </template>
    <template #footer>
        <n-button
            :color="gl.mainColor"
            :loading="addInsuranceOrderLoading"
            :disabled="addInsuranceOrderLoading"
            class="text-white/90 rounded-md w-full"
            @click="onAddInsuranceOrder">
            Усреднить позицию
        </n-button>
    </template>
</base-drawer-mobile>

</template>

<script>
// vue
import { ref, reactive, computed, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useStats } from '@store/stats';

// i18n
import { useI18n } from 'vue-i18n';

// services
import StatsService from '@services/statsService';
import WalletsService from '@services/walletsService';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';

// router
import { useRoute } from 'vue-router';

// UI
import {
    NCard,
    NAlert,
    NButton,
    NDivider,
    NStatistic,
    NNumberAnimation,
    useNotification } from 'naive-ui';

export default {
    name: 'insurance-order-mobile',
    props: {
        botShortInfo: {
            type: Object,
            required: true,
        },
    },
    components: {
        NCard,
        RbCoin,
        NAlert,
        NButton,
        RbInput,
        NDivider,
        RbSelect,
        RbCheckbox,
        NStatistic,
        NNumberAnimation,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const stats = useStats();

        // naive-ui
        const notification = useNotification();

        // i18n
        const { t } = useI18n();

        // router
        const route = useRoute();

        // vars
        const show = ref(false);
        const checkBalanceLoading = ref(false);
        const addInsuranceOrderLoading = ref(false);
        const orderExecution = ref('limit');
        const calculateShow = ref(false);
        const balancesRefs = ref();
        const model = reactive({
            market: computed(() => orderExecution.value === 'market'),
            amount: undefined,
            rate: undefined,
        });

        const coin = computed(() => {
            return !props.botShortInfo.futures
                ? props.botShortInfo.tickers_for.split('/')[props.botShortInfo.algo === 'long' ? 1 : 0]
                : props.botShortInfo.tickers_for.split('/')[props.botShortInfo.extra_data?.coin === 1 ? 0 : 1];
        });

        const orderExecutionTypes = computed(() => stats.orderExecutionTypes.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));

        const symbolBalance = computed(() => balancesRefs.value?.[coin.value] || '');

        const onCheckBalance = async () => {
            if (!props.botShortInfo?.api_id) return;

            if(props.botShortInfo?.status != 1) {
                return void notification['error']({
                    content: stats.localization['bot_not_running'],
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }

            checkBalanceLoading.value = true;

            const prepare = await WalletsService.getBalances(props.botShortInfo.api_id);

            if (!prepare.data.status) {
                prepare.data.errors.forEach(({ msg }) => {
                    notification['error']({
                        content: msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });

                // reset balancesRefs
                balancesRefs.value = null;
            } else {
                balancesRefs.value = prepare.data.balances;

                // show modal
                show.value = true;
            }

            checkBalanceLoading.value = false;
        };

        const onAddInsuranceOrder = async () => {
            addInsuranceOrderLoading.value = true;

            try {
                const prepare = await StatsService.addInsuranceOrder({
                    id: props.botShortInfo.id,
                    amount: +model.amount,
                    rate: +model.rate,
                    market: model.market,
                });

                if (!prepare.data.status) {
                    if (prepare.data?.errors) {
                        prepare.data.errors.forEach(({ msg }) => {
                            notification.error({
                                content: msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });
                    }
                } else {
                    prepare.postMessages.forEach(({ msg }) => {
                        notification.success({
                            content: msg,
                            duration: 2500,
                            keepAliveOnHover: true,
                        });
                    });

                    // close modal
                    show.value = false;
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };

            addInsuranceOrderLoading.value = false;
        };

        const setPrice = price => {
            if (!model.market) {
                model.rate = price;
            }
        };

        return {
            gl,
            coin,
            show,
            stats,
            model,
            symbolBalance,
            calculateShow,
            orderExecution,
            checkBalanceLoading,
            orderExecutionTypes,
            addInsuranceOrderLoading,
            t,
            setPrice,
            onCheckBalance,
            onAddInsuranceOrder,
            currency: icon => refs.currencies.find(el => el.id === icon),
            exchange: exchange => refs.exchanges.find(el => el.id === exchange),
        };
    },
};
</script>