<template>
<div v-if="stats.localization" class="min-h-screen px-3">
    <section class="flex justify-start mt-2">
        <n-icon
            size="20"
            class="text-gray-600 dark:text-white/75"
            @click="router.go(-1)">
            <ArrowLeft12Regular />
        </n-icon>
    </section>

    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg mt-4 rounded-md"
        content-style="padding: 0;"
        :segmented="{
          content: true,
          footer: 'soft'
        }">
        <template #header>
            <div v-if="dashProfit.title" class="flex justify-center text-sm text-gray-600 dark:text-white/75 font-semibold">
              <n-skeleton v-if="loading" :width="215" :height="20" :sharp="false" />
              <template v-else>{{ dashProfit.title }}</template>
            </div>

            <div class="flex justify-between items-center px-3 py-2 text-gray-600 dark:text-white/75">
                <div class="text-xs font-semibold">{{ dashProfit.data?.btc }} {{ env.profitSymbol.toUpperCase() }} / {{dashProfit.data?.usdt || '---'}} USDT</div>

                <div class="text-xs">( {{ dashProfit.ciclos }} {{ stats.localization['dashboard_profit_total_cycles_completed'] }} )</div>
            </div>
        </template>
        <div class="flex flex-col">
            <div
                v-for="(stat, i) in dashProfit.stats.slice(0, 7)"
                :key="i"
                class="w-full dark:odd:bg-[#242424] odd:bg-gray-50">
                <div class="py-[2px] px-3 flex flex-row justify-start items-center overflow-x-hidden relative">
                    <div class="w-4/12 flex items-center text-gray-600 dark:text-white/75">
                        <rb-coin class="w-5 mr-3" :coin="currency(stat[2])" />
                        <div class="text-xs font-semibold uppercase">{{ stat[2] }}</div>
                    </div>
                    
                    <div class="text-xs font-semibold" :class="stat[0] > 0 ? 'text-green-600' : 'text-red-500'">{{ stat[0] }}</div>

                    <div
                        v-if="stat[2] !== env.profitSymbol"
                        class="flex flex-row opacity-60 items-center text-white justify-start absolute w-4/5 h-full bg-main right-[-72%] rounded-l-md transition-all cursor-pointer z-50"
                        @click="mouseover">
                        <n-icon class="absolute left-3 top-1/2 -translate-y-1/2 pointer-events-none">
                            <ChevronLeft24Filled />
                        </n-icon>

                        <div class="w-3/12 flex items-center ml-8">
                            <rb-coin class="mx-2 w-5" :coin="currency(env.profitSymbol)" />
                            <div class="text-xs font-semibold uppercase">{{ env.profitSymbol }}</div>
                        </div>
                        
                        <div class="text-xs font-semibold">{{ stat[1] }}</div>
                    </div>
                </div>
            </div>
            <n-button
                v-if="dashProfit.stats.length > 7"
                strong
                secondary
                size="large"
                class="rounded-none"
                @click="letsShowMore(dashProfit)">
                <div class="flex">
                    <span class="text-xs mr-2">{{ t('more') }}</span>
                    <n-icon><ArrowRight12Regular /></n-icon>
                </div>
            </n-button>
        </div>
    </n-card>

    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md mt-4"
        :segmented="{
          content: true,
          footer: 'soft'
        }">
        <template #header>
            <div class="flex flex-wrap items-start pt-1 text-gray-600 dark:text-white/75 text-left text-xs">
                <div class="mr-4">
                    {{ stats.localization['trade_statistics_management_botname'] }}<br />
                    <strong class="text-main font-bold">{{ botShortInfo?.bot_name }}</strong>
                </div>
                <div>
                    {{ stats.localization['trade_statistics_management_botstatus'] }}<br />
                    <strong class="text-main font-bold">{{ botShortInfo?.status_txt }}</strong>
                </div>
                <div class="w-full mt-4">
                    {{ stats.localization['trade_statistics_management_botstate'] }}<br />
                    <strong class="text-main font-bold">{{ botShortInfo?.state_txt }}</strong>
                </div>
            </div>
        </template>
        <template #default>
            <div class="flex items-center flex-wrap text-gray-600 dark:text-white/75 text-xs whitespace-nowrap">
                <span>{{ stats.localization['trade_statistics_management_current'] }}&nbsp;</span>
                <span class="rb-td-stock-icon w-12" :class="`rb-td-stock-icon-${exchange(botShortInfo?.exchange)?.code_name}`"></span>
                <span>{{ stats.localization['trade_statistics_management_tickers_for'] }}</span>
                <div class="ml-2 flex items-center">
                    <rb-coin class="w-6" :coin="currency(botShortInfo?.tickers_for?.split('/').shift().trim())" />
                    <div class="ml-2">{{ botShortInfo?.tickers_for }}</div>
                </div>
            </div>
            <div class="flex items-center justify-between flex-wrap text-gray-600 dark:text-white/75 text-xs whitespace-nowrap">
                <span>{{ stats.localization['trade_statistics_management_currenttickers_buy'] }}</span>
                <strong class="text-main font-bold text-base">&nbsp;{{ botShortInfo?.buy }}</strong>
                <span class="ml-2">{{ stats.localization['trade_statistics_management_currenttickers_sell'] }}</span>
                <strong class="text-main font-bold text-base">&nbsp;{{ botShortInfo?.sell }}</strong>

                <n-button
                    strong
                    size="small"
                    class="text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="totalUpdateLoading"
                    :loading="totalUpdateLoading"
                    @click="totalUpdate">
                    {{ stats.localization['trade_statistics_bots_table_data_auto_refresh_button'] }}
                </n-button>
            </div>
            <n-divider class="mt-4" />

            <div class="flex flex-wrap">
                <n-icon
                    v-if="botShortInfo?.exit_error"
                    class="text-red-500 cursor-pointer text-4xl mr-6"
                    @click="message.error(botShortInfo?.exit_error)">
                    <ErrorCircle20Regular />
                </n-icon>
                <template v-for="item in buttons" :key="item.label">
                    <n-button
                        v-if="item.show"
                        size="small"
                        class="mr-3 mb-3 text-white/90 rounded-md"
                        :color="gl.mainColor"
                        :loading="item.loading"
                        :disabled="item.loading"
                        @click="item.fn">
                        {{ item.label }}
                    </n-button>
                </template>

                <reset-fix-order
                    :botShortInfo="botShortInfo"
                    :reSetFixOrder="reSetFixOrder"
                    @getData="getAll" />

                <insurance-order
                    v-if="!botShortInfo?.simulate"
                    :botShortInfo="botShortInfo" />
            </div>
        </template>
    </n-card>

    <super-table
        v-if="init"
        showSearch
        class="mt-4"
        :filters="filters"
        :refFilters="refFilters"
        :pager="stats.cyclesDataTable.pager"
        :columns="columns"
        :records="stats.cyclesDataTable.records"
        :filtersChanged="filtersChanged"
        columnsStorageName="__columns_cycles_table"
        type="cycles_table"
        @getData="getAll"
        @sortColumn="sortColumn"
        @doSomething="doSomething"
        @reset-filters="resetFilters"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-sm text-left mb-2 text-gray-600 dark:text-white/75">
                {{ stats.localization['trade_statistics_management_cyclesinfo'] }}
            </div>
        </template>
        <template #stats>
            <n-card
                size="small"
                :bordered="false"
                class="shadow bg-white dark:bg-darkCardBg rounded-md my-2"
                content-style="padding-bottom: 4px;"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <div class="text-[10px] text-gray-600 dark:text-white/75 text-left flex flex-wrap">
                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_management_cyclesinfo_totalcycles'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="warning" class="ml-2">
                            <span class="text-yellow-500 font-semibold">{{ stats.cyclesDataTable.stats.total }}</span>
                        </n-tag>
                    </div>

                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_management_ordershistory_running'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="success" class="ml-2">
                            <span class="text-green-500 font-semibold">{{ stats.cyclesDataTable.stats.running }}</span>
                        </n-tag>
                    </div>
                    
                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_management_cyclesinfo_completed'] }}</span>
                        <n-tag round size="tiny" :bordered="false" class="ml-2">
                            <span class="font-semibold">{{ stats.cyclesDataTable.stats.completed }}</span>
                        </n-tag>
                    </div>

                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_management_ordershistory_canceled'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="error" class="ml-2">
                            <span class="text-red-400 font-semibold">{{ stats.cyclesDataTable.stats.canceled }}</span>
                        </n-tag>
                    </div>
                </div>
            </n-card>
        </template>
        <template #afterSearch>
            <div class="flex items-center justify-start mx-2">
                <div class="hover:text-main dark:hover:text-main text-gray-600 dark:text-white/75 cursor-pointer mr-2" @click="downloadData('xls')">
                    <n-icon class="text-2xl">
                        <ClipboardDataBar24Regular />
                    </n-icon>
                    <div class="text-xs text-center -mt-2 font-bold">XLS</div>
                </div>
                <div class="hover:text-main dark:hover:text-main text-gray-600 dark:text-white/75 cursor-pointer ml-2" @click="downloadData('csv')">
                    <n-icon class="text-2xl">
                        <ClipboardDataBar24Regular />
                    </n-icon>
                    <div class="text-xs text-center -mt-2 font-bold">CSV</div>
                </div>
            </div>
        </template>
    </super-table>

    <super-table
        v-if="stats.ordersDataTable"
        id="cycles-orders-table"
        class="mt-4"
        :filters="ordersFilters"
        :pager="stats.ordersDataTable.pager"
        :columns="columnsOrdersTable"
        :records="stats.ordersDataTable.records"
        columnsStorageName="__columns_cycles_orders_table"
        type="cycles_orders_table"
        @getData="ordersGet"
        @applyFilters="changeFilter2">
        <template #tableTitle>
            <div class="text-sm text-left mb-2 text-gray-600 dark:text-white/75">
                {{ stats.localization['trade_statistics_management_ordershistory_title'] + ` ${stats.currentCycle}` }}
            </div>
        </template>
        <template #afterLabel>
            <rb-checkbox
                :label="stats.localization['trade_statistics_management_ordershistory_show_orders_for_all_cycles_checkbox']"
                v-model:checked="stats.forAllCycles" />
        </template>
        <template #stats>
            <n-card
                size="small"
                :bordered="false"
                class="shadow bg-white dark:bg-darkCardBg rounded-md my-2"
                content-style="padding-bottom: 4px;"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <div class="text-[10px] text-gray-600 dark:text-white/75 text-left flex flex-wrap">
                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_management_ordershistory_total'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="warning" class="ml-2">
                            <span class="text-yellow-500 font-semibold">{{ stats.ordersDataTable.stats.total }}</span>
                        </n-tag>
                    </div>

                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_bots_running'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="success" class="ml-2">
                            <span class="text-green-500 font-semibold">{{ stats.ordersDataTable.stats.running }}</span>
                        </n-tag>
                    </div>

                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_management_ordershistory_canceled'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="error" class="ml-2">
                            <span class="text-red-400 font-semibold">{{ stats.ordersDataTable.stats.canceled }}</span>
                        </n-tag>
                    </div>
                    
                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_management_ordershistory_partcanceled'] }}</span>
                        <n-tag round size="tiny" :bordered="false" class="ml-2">
                            <span class="font-semibold">{{ stats.ordersDataTable.stats.part_canceled }}</span>
                        </n-tag>
                    </div>

                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_management_ordershistory_completed'] }}</span>
                        <n-tag round size="tiny" :bordered="false" class="ml-2" type="info">
                            <span class="text-blue-400 font-semibold">{{ stats.ordersDataTable.stats.completed }}</span>
                        </n-tag>
                    </div>
                </div>
            </n-card>
        </template>
        <template #afterSearch>
            <div class="flex items-center justify-start mx-2">
                <div class="hover:text-main dark:hover:text-main text-gray-600 dark:text-white/75 cursor-pointer mr-2" @click="downloadData2('xls')">
                    <n-icon class="text-2xl">
                        <ClipboardDataBar24Regular />
                    </n-icon>
                    <div class="text-xs text-center -mt-2 font-bold">XLS</div>
                </div>
                <div class="hover:text-main dark:hover:text-main text-gray-600 dark:text-white/75 cursor-pointer ml-2" @click="downloadData2('csv')">
                    <n-icon class="text-2xl">
                        <ClipboardDataBar24Regular />
                    </n-icon>
                    <div class="text-xs text-center -mt-2 font-bold">CSV</div>
                </div>
            </div>
        </template>
    </super-table>
</div>
<!-- <div v-if="stats.localization">

    <cycles-orders-table
        v-if="stats.ordersDataTable"
        :filters="ordersFilters"
        @getData="ordersGet($event)"
        @apply="changeOrdersFilters([$event.filter, $event.value], $event.update)" />


</div> -->

<base-dialog-mobile
    v-model:show="cancelOrders"
    @positive-click="onCancelOrders"
    @negative-click="cancelOrders = false">
    <div class="text-md" v-html="stats.localization['trade_statistics_management_cancelorders_button']"></div>
    <template #body>
        <n-divider />
        <rb-checkbox
            :label="stats.localization['trade_statistics__stop_bot_after_operation']"
            v-model:checked="stopBotAfterOperation" />
    </template>
</base-dialog-mobile>

<base-dialog-mobile
    v-model:show="showConfirmation"
    @positive-click="showConfirmation = false, onGeneralConfirmClicked(action)"
    @negative-click="showConfirmation = false">
    <div class="text-md" v-html="refs.localization.confirmations.bot[action]"></div>
</base-dialog-mobile>

<base-dialog-mobile
    v-model:show="markLastAsCanceled"
    @positive-click="doMarkLastAsCanceled"
    @negative-click="markLastAsCanceled = false">
    <div class="text-md" v-html="stats.localization['mark_last_cycle_canceled']"></div>
</base-dialog-mobile>

<base-modal-mobile
    v-model:show="showConfirmationCheck">
    <template #default>
        <div class="text-md text-gray-600 dark:text-white/75">{{ declineMessage }}</div>
    </template>
    <template #footer>
        <div class="flex items-center justify-end">
            <rb-checkbox
                :label="'- ' + confirmLabel"
                v-model:checked="genConfCheck" />

            <n-button
                strong
                class="ml-3 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!genConfCheck"
                @click="showConfirmationCheck = false, onGeneralConfirmClicked('start')">
                {{ refs.localization.dialogs.ok }}
            </n-button>
        </div>
    </template>
</base-modal-mobile>

<n-drawer
    v-model:show="showMore"
    width="100%"
    class="bg-gray-100 dark:bg-darkbg">
    <n-drawer-content body-content-style="padding: 0">
        <div v-if="info" class="flex flex-col h-full">
            <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4">
                <n-icon
                    size="20"
                    class="text-gray-600 dark:text-white/75 absolute top-1/2 left-2 -translate-y-1/2"
                    @click="showMore = false">
                    <ArrowLeft12Regular />
                </n-icon>
                {{ info.title }}
            </div>
            <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                <div
                    v-for="(stat, i) in info.stats"
                    :key="i"
                    class="w-full dark:odd:bg-[#242424] odd:bg-gray-50">
                    <div class="py-[2px] px-3 flex flex-row justify-start items-center overflow-x-hidden relative">
                        <div class="w-4/12 flex items-center text-gray-600 dark:text-white/75">
                            <rb-coin class="w-5 mr-3" :coin="currency(stat[2])" />
                            <div class="text-xs font-semibold uppercase">{{ stat[2] }}</div>
                        </div>
                        
                        <div class="text-xs font-semibold" :class="stat[0] > 0 ? 'text-green-600' : 'text-red-500'">{{ stat[0] }}</div>

                        <div
                            v-if="stat[2] !== env.profitSymbol"
                            class="flex flex-row opacity-60 hover:opacity-100 items-center text-white justify-start absolute w-4/5 h-full bg-main right-[-72%] rounded-l-md profit transition-all"
                            @click="mouseover">
                            <n-icon class="absolute left-3 top-1/2 -translate-y-1/2 pointer-events-none">
                                <ChevronLeft24Filled />
                            </n-icon>

                            <div class="w-3/12 flex items-center ml-8">
                                <rb-coin class="mx-2 w-5" :coin="currency(env.profitSymbol)" />
                                <div class="text-xs font-semibold uppercase">{{ env.profitSymbol }}</div>
                            </div>
                            
                            <div class="text-xs font-semibold">{{ stat[1] }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </n-drawer-content>
</n-drawer>
</template>

<script>
// general
import general from './general';

// vue
import { ref } from 'vue';

// icon
import {
    ArrowLeft12Regular,
    ChevronLeft24Filled,
    ErrorCircle20Regular,
    ArrowRight12Regular,
    ClipboardDataBar24Regular } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import RbSwitch from '@components/rb-switch/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import FilterBots from '@components/filters/bots';
import ResetFixOrder from '@components/stats-management/reset-fix-order/mobile.vue';
import BaseModal from '@components/base/base-modal';
import FilterStates from '@components/filters/states';
import FilterStatuses from '@components/filters/statuses';
import SuperTable from '@components/super-table/mobile.vue';
import FilterExchanges from '@components/filters/exchanges';
import FilterTaksonomiya from '@components/filters/taksonomiya';
import InsuranceOrder from '@components/stats-management/insurance-order/mobile.vue';

// UI
import {
    NTag,
    NIcon,
    NCard,
    NTabs,
    NModal,
    NButton,
    NTabPane,
    NDivider,
    NDrawer,
    NDrawerContent,
    NSkeleton,
    NScrollbar,
    NDatePicker,
    useMessage,
    useNotification } from 'naive-ui';

export default {
    name: 'managment-mobile',
    components: {
        NTag,
        NIcon,
        NCard,
        NTabs,
        NModal,
        RbCoin,
        NButton,
        NDrawer,
        NDivider,
        RbSwitch,
        NTabPane,
        SuperTable,
        BaseModal,
        NSkeleton,
        RbCheckbox,
        NScrollbar,
        FilterBots,
        NDatePicker,
        FilterStates,
        ResetFixOrder,
        FilterStatuses,
        InsuranceOrder,
        FilterExchanges,
        NDrawerContent,
        ArrowRight12Regular,
        ArrowLeft12Regular,
        FilterTaksonomiya,
        ChevronLeft24Filled,
        ErrorCircle20Regular,
        ClipboardDataBar24Regular,
    },
    setup(props, context) {
        // vars
        const info = ref();
        const showMore = ref();
        const currentEl = ref();
        
        const mouseover = $event => {
            if (currentEl.value) {
                currentEl.value.classList.remove('right-0', 'opacity-100');
            };
            
            currentEl.value = $event.target;
            currentEl.value.classList.add('right-0', 'opacity-100');

            setTimeout(() => {
                document.addEventListener('click', mouseleave);
            }, 200);
            
        };

        const mouseleave = $event => {
            if (currentEl.value && currentEl.value !== $event.target) {
                currentEl.value.classList.remove('right-0', 'opacity-100');
                setTimeout(() => {
                    document.removeEventListener('click', mouseleave);
                }, 200);
            }
        };

        const letsShowMore = item => {
            info.value = item;
            showMore.value = true;
        };

        return {
            ...general(arguments),
            info,
            showMore,
            mouseover,
            letsShowMore,
        };
    },
};
</script>